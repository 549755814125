import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";
import Stack from "@mui/material/Stack";
import {Accordion, AccordionDetails, AccordionSummary, ListItem} from "@mui/material";
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function DogBoardingRules() {
    return <MKBox component="section" py={{xs: 3, md: 3}} sx={{ml: 4}}>
        <Container>
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
            >
                <MKTypography variant="h2" fontWeight="bold">
                    Conditions and Policies
                </MKTypography>
            </Grid>
            <Stack>
                <MKBox display="flex" alignItems="center" p={2}>

                </MKBox>
                <MKBox display="flex" alignItems="center" p={2}>
                    <Stack>
                        <MKTypography variant="h4" my={1}>
                            Additional Fees
                        </MKTypography>
                        <ListItem>
                            <Accordion isableGutters
                                       square={true}
                                       elevation={0}
                                       sx={{
                                           '&:before': {
                                               display: 'none',
                                           }
                                       }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{px: 0.8}}>
                                    <ListItem sx={{display: "list-item"}}>
                                        A $20 per day holiday fee is charged on:  (<u>click to see all holidays</u>)
                                    </ListItem>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack sx={{ml: 4}}>
                                        <ListItem sx={{padding: 0}}>
                                            Christmas, New Year and weekend: Dec 21, 2024 - Jan 1, 2025
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Martin Luther King Day and weekend: Jan 17-20, 2025
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Presidents' Day and weekend: Feb 14-17, 2025
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Memorial Day and Weekend: May 23-26, 2025
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Juneteenth and Weekend: Jun 19-22, 2025
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Independence Day and Weekend: Jul 4-6, 2025
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Labor Day and Weekend: Aug 29- Sep 1, 2025
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Thanksgiving Day and Weekend: Nov 27-30, 2025
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Christmas, New Year and weekend: Dec 24, 2025 - Jan 1, 2026
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Martin Luther King Day and weekend: Jan 16-19, 2026
                                        </ListItem>
                                        <ListItem sx={{padding: 0}}>
                                            Presidents' Day and weekend: Feb 13-16, 2026
                                        </ListItem>
                                    </Stack>

                                </AccordionDetails>
                            </Accordion>
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            Rush fee: Bookings with less than 24 hours' notice. 20% extra for regular days, 50% extra for holidays.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            After-hours care: Drop-offs and pick-ups outside regular hours are charged at double extended hour rate.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            Puppy under six months $10 per day, Puppy under one year $5 per day.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            Unneutered XSmall/Small dog $5 per day, Unneutered Medium/Large dog $10 per day.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            XSmall/Small dog pee&poop in the house $5 per day, Medium/Large dog pee&poop in the house
                            $10 per day.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            Un-crate trained XSmall/Small dog $5 per day, Un-crate trained Medium/Large dog $10 per
                            day.
                        </ListItem>
                    </Stack>
                </MKBox>
            </Stack>
            <MKBox display="flex" alignItems="center" p={2}>
                <Stack>
                    <MKTypography variant="h4" my={1}>
                        Conditions
                    </MKTypography>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        A Meet&Greet should be scheduled before dog boarding. No walk-in service available.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        21 days+ Boarding 10% off, 30 days+ Boarding 20% off, each additional dog per family 20% off .
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        30 Boarding pass: 20% off. For repeat customers only.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        4h Daycare: Boarding price - $10 per day, 8h Daycare: Boarding price - $5 per day.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        30 Daycare pass: 20% off. For repeat customers only.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        A Rabies vaccine certificate should be provided.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item"}}>
                        Dogs who will attack people or other dogs, bark at home frequently and have infectious diseases
                        are not accepted.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        Contact us for a free quote if your dog is over 60 lbs.
                    </ListItem>
                </Stack>
            </MKBox>
            <MKBox display="flex" alignItems="center" p={2}>
                <Stack>
                    <MKTypography variant="h4" my={1}>
                        Payment Policies
                    </MKTypography>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        Payment is due upon drop off. Venmo, Zelle, Paypal, Cash are accepted.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        Daycare passes and Boarding passes are non-refundable and valid for one year.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item",}}>
                        20% deposit is required for holiday reservations.
                    </ListItem>
                    <ListItem sx={{padding: 1, display: "list-item"}}>
                        Deposits are non-refundable.
                    </ListItem>
                </Stack>
            </MKBox>
        </Container>
    </MKBox>;
}