import Container from "@mui/material/Container";
import MKBox from "../../../components/MKBox";
import React, {useEffect, useState} from 'react'
import routes from "../../../routes";
import DefaultFooter from "../../footer/Footer";
import footerRoutes from "../../footer/footer.routes";
import DefaultNavbar from "../../navigation/DefaultNavbar";
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MKTypography from "../../../components/MKTypography";
import Grid from "@mui/material/Grid";
import BoardingInformation from "../BoardingInformation";
import BoardingFAQ from "../BoardingFAQ";
import SmallAnimalBoardingRules from "./SmallAnimalBoardingRules";
import breakpoints from "../../../assets/theme/base/breakpoints";
import {smallAnimalFAQData} from "./data/SmallAnimalFAQData";
import {smallAnimalPriceData} from "./data/SmallAnimalPriceData";
import NonCollapsibleRow from "../NonCollapsibleRow";


function SmallAnimal() {
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }

        /**
         The event listener that's calling the displayMobileNavbar function when
         resizing the window.
         */
        window.addEventListener("resize", displayMobileNavbar);

        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    return (
        <>
            <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
                <MKBox bgColor="white" shadow="sm" py={0.25}>
                    <DefaultNavbar
                        routes={routes}
                        transparent
                        relative
                    />
                </MKBox>
                <Container sx={{mt: 6}}>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        flexDirection="column"
                        alignItems="center"
                        sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
                    >
                        <MKTypography variant="h2" fontWeight="bold">
                            Small Animal Boarding
                        </MKTypography>
                        <MKTypography variant="body1" color="text">

                        </MKTypography>
                    </Grid>
                    <MKBox>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {/*<StyledTableCell/>*/}
                                        <StyledTableCell>Animal</StyledTableCell>
                                        {!mobileView && <StyledTableCell align="right">Above 1 month</StyledTableCell>}
                                        {!mobileView && <StyledTableCell align="right">Within 4 weeks</StyledTableCell>}
                                        {!mobileView && <StyledTableCell align="right">Within 1 week</StyledTableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {smallAnimalPriceData.map((priceData) => <NonCollapsibleRow
                                        mobileView={mobileView}
                                        priceData={priceData}/>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <SmallAnimalBoardingRules/>
                        <BoardingInformation/>
                        <BoardingFAQ faqs={smallAnimalFAQData}/>
                        {/*<Testimonial reviewData={smallAnimalReviewData}/>*/}
                    </MKBox>
                </Container>
                <MKBox mt="auto">
                    <DefaultFooter content={footerRoutes}/>
                </MKBox>
            </MKBox>
        </>
    )
        ;
}

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#44b04e",
        color: theme.palette.common.white,
        fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 18,
    },
}));

export const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default SmallAnimal;
