import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";
import Stack from "@mui/material/Stack";
import {ListItem} from "@mui/material";
import React from 'react'

export default function SmallAnimalBoardingRules() {
    return <MKBox component="section" py={{xs: 3, md: 3}} sx={{ml: 4}}>
        <Container>
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
            >
                <MKTypography variant="h2" fontWeight="bold">
                    Conditions and Policies
                </MKTypography>
            </Grid>
            <Stack>
                <MKBox display="flex" alignItems="center" p={2}>
                    <Stack>
                        <MKTypography variant="h4" my={1}>
                            Additional Fees and Conditions
                        </MKTypography>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            Rush fee: Bookings with less than 24 hours' notice. 20% extra for regular days, 50% extra for holidays.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            After-hours care: Drop-offs and pick-ups outside regular hours are charged at double extended hour rate.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            A Meet&Greet is always welcome. No walk-in service available.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            Additional fees may apply if your pets have special needs.
                        </ListItem>
                    </Stack>
                </MKBox>
                <MKBox display="flex" alignItems="center" p={2}>
                    <Stack>
                        <MKTypography variant="h4" my={1}>
                            Policies
                        </MKTypography>
                        <ListItem sx={{padding: 1, display: "list-item",}}>
                            Payment is due upon drop off. Venmo, Zelle, Paypal, Cash are accepted.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            20% deposit is required for holiday reservations.
                        </ListItem>
                        <ListItem sx={{padding: 1, display: "list-item"}}>
                            Deposits are non-refundable.
                        </ListItem>
                    </Stack>
                </MKBox>
            </Stack>
        </Container>
    </MKBox>;
}