import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CardHeader from "@mui/material/CardHeader";
import MKAvatar from "../../components/MKAvatar";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import PricingCard from "./PricingCard";
import {StyledTableCell, StyledTableRow} from "./small/SmallAnimal";
import SteppingPricingCard from "./SteppingPricingCard";

function HorizontalPlan({plans, categories, animal, unit}) {
    if (animal === "Dog") {
        return <Grid container spacing={2} sx={{p: 2, mb: 3}}>
            {plans.map((plan) => {
                return <Grid item xs={12} lg={12 / plans.length}>
                    <SteppingPricingCard
                        color="info"
                        bgColor={plan.bgColor}
                        title={plan.name}
                        price={plan.price}
                        points={plan.points}
                        categories={categories}
                        animal={animal}
                        unit={unit}
                    />
                </Grid>
            })}
        </Grid>;
    }
    return <Grid container spacing={2} sx={{p: 2, mb: 3}}>
        {plans.map((plan) => {
            return <Grid item xs={12} lg={12 / plans.length}>
                <PricingCard
                    color="info"
                    bgColor={plan.bgColor}
                    title={plan.name}
                    price={plan.price}
                    points={plan.points}
                    categories={categories}
                    animal={animal}
                    unit={unit}
                />
            </Grid>
        })}
    </Grid>;
}

function PricingDetail({plans, categories, animal, unit, planDescription}) {
    return <>
        <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{textAlign: "center", my: 3, mx: "auto", px: 0.75}}
        >
            <MKTypography variant="h2" fontWeight="bold">
                Plans
            </MKTypography>
            <MKTypography variant="body1" color="text">
                {planDescription}
            </MKTypography>
        </Grid>
        <HorizontalPlan plans={plans} categories={categories} animal={animal} unit={unit}/>
    </>;
}

export default function CollapsibleRow({priceData, mobileView = false, openByDefault = false}) {
    const [open, setOpen] = useState(openByDefault);
    return <>
        <StyledTableRow key={priceData.name}>
            <StyledTableCell component="th" scope="row">
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </StyledTableCell>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
                <CardHeader
                    avatar={
                        <MKAvatar src={priceData.icon} size={priceData ? priceData.iconSize : 'lg'} variant="rounded"/>
                    }
                    title={priceData.name}
                />
            </StyledTableCell>
            {!mobileView && <StyledTableCell align="right">{priceData.plans[0]?.price}</StyledTableCell>}
            {!mobileView && <StyledTableCell align="right">{priceData.plans[1]?.price}</StyledTableCell>}
            {!mobileView && <StyledTableCell align="right">{priceData.plans[2]?.price}</StyledTableCell>}
        </StyledTableRow>
        <StyledTableRow>
            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <PricingDetail plans={priceData.plans} categories={priceData.categories} animal={priceData.animal}
                                   unit={priceData.unit} planDescription={priceData?.planDescription}/>
                </Collapse>
            </TableCell>
        </StyledTableRow>
    </>;
}